import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { List, Statistic, message } from "antd";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { gettext as _ } from "ttag";

import { HomeMeshType, IsMobileContext, startPeriodicRefetch } from "../utils";
import {
  Container,
  ListItemCard,
  Navbar,
  HealthStatusStatistic,
} from "../components";
import "./HomeMeshListPage.css";

const HomeMeshListPage = () => {
  const history = useHistory();
  const isMobile = useContext(IsMobileContext);
  const [data, setData] = useState<HomeMeshType[]>([]);

  const fetchInitialData = useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/home-mesh/`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const json = await response.json();
    if (response.status === 403) {
      Cookies.remove("csrftoken");
      history.push("/");
      message.error(_("Please login to proceed."));
    } else if (json.length === undefined) {
      message.error(json.detail);
    } else {
      setData(json);
    }
  }, [history]);
  useEffect(() => {
    fetchInitialData();
    const stopPeriodifcRefetch = startPeriodicRefetch(fetchInitialData);
    return () => {
      stopPeriodifcRefetch();
    };
  }, [fetchInitialData]);

  const isLoading = data.length === 0;

  if (data.length === 1) {
    history.push(`/home-mesh/${data[0].id}`);
  }
  return (
    <>
      <Navbar />
      <Container isMobile={isMobile}>
        {!isLoading && (
          <>
            <h1 style={{ alignSelf: isMobile ? "" : "flex-start" }}>
              {_("Your Home Mesh")}
            </h1>
          </>
        )}
        <List
          itemLayout="horizontal"
          dataSource={data}
          loading={{ size: "large", spinning: isLoading }}
          renderItem={(mesh) => {
            const status = mesh.devices.some(
              (item) => item.monitoring_status === "unknown"
            )
              ? "unknown"
              : mesh.devices.some(
                  (item) => item.monitoring_status === "critical"
                )
              ? "critical"
              : "ok";
            return (
              <Link to={`/home-mesh/${mesh.id}`}>
                <ListItemCard
                  name={mesh.name}
                  statistics={[
                    <Statistic
                      title={_("Mesh Points")}
                      valueStyle={{ fontSize: "1.3rem", minWidth: "90px" }}
                      value={mesh.devices.length}
                      key="devices"
                    />,
                    <Statistic
                      title={_("Connected Devices")}
                      valueStyle={{ fontSize: "1.3rem", minWidth: "6rem" }}
                      value={mesh.wifi_clients.length}
                      key="wifi_clients"
                    />,
                    <HealthStatusStatistic status={status} />,
                  ]}
                  spaceSize="small"
                />
              </Link>
            );
          }}
        />
      </Container>
    </>
  );
};

export default HomeMeshListPage;
