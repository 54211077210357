import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import { List, Statistic, message, Tabs, Space } from "antd";
import { gettext as _ } from "ttag";

import {
  Device,
  HomeMeshType,
  IsMobileContext,
  startPeriodicRefetch,
} from "../utils";
import { deviceIcon, settingsIcon, meshPointIcon } from "../icons";
import {
  WifiSettingsForm,
  Container,
  ListItemCard,
  HealthStatusStatistic,
  Navbar,
  DeviceNameModal,
} from "../components";

const { TabPane } = Tabs;

const NaviationTabPane: React.FC<{
  icon: string;
  amount?: number;
  name: string;
}> = ({ icon, amount = null, name }) => {
  const isMobile = useContext(IsMobileContext);
  const SIZE = isMobile ? "2rem" : "3rem";
  return (
    <Space direction="vertical">
      <img src={icon} alt="Clients" style={{ height: SIZE, width: SIZE }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize: isMobile ? "0.8rem" : "1.0rem",
        }}
      >
        {amount ? (
          <>
            {name} ({amount})
          </>
        ) : (
          <>{name}</>
        )}
      </div>
    </Space>
  );
};

const HomeMeshSinglePage = () => {
  const [view, setView] = useState<string>("routers");
  const history = useHistory();
  const isMobile = useContext(IsMobileContext);
  let { id }: { id?: string | undefined } = useParams();
  const [data, setData] = useState<HomeMeshType | null>(null);
  const [device, setDevice] = useState<Device | null>(null);
  const SPACE_SIZE = useMemo(() => {
    return isMobile ? 0 : "middle";
  }, [isMobile]);
  const fetchInitialData = useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/home-mesh/${id}/`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const json = await response.json();
    if (response.status === 403) {
      history.push("/");
      message.error(_("Please login to proceed."));
    } else if (!json.id) {
      history.goBack();
      message.error(_("Something went wrong. Please contact support."));
    }
    setData(json);
  }, [history, id]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData, device]);

  useEffect(() => {
    const stopPeriodicRefetch = startPeriodicRefetch(fetchInitialData);
    return () => {
      stopPeriodicRefetch();
    };
  }, [fetchInitialData]);

  const isLoading = data === null;
  return (
    <>
      <Navbar />
      <Container isMobile={isMobile}>
        {!isLoading && (
          <Tabs
            defaultActiveKey="1"
            onChange={(view) => {
              setView(view);
              if (view === "settings") {
                fetchInitialData();
              }
            }}
            style={{ maxWidth: "auto", textAlign: "center" }}
          >
            <TabPane
              tab={
                <NaviationTabPane
                  name={_("Mesh Points")}
                  amount={data?.devices.length ?? 0}
                  icon={meshPointIcon}
                />
              }
              key="routers"
              style={{ fontSize: "100px" }}
            ></TabPane>
            <TabPane
              tab={
                <NaviationTabPane
                  name={_("Connected Devices")}
                  amount={data?.wifi_clients.length ?? 0}
                  icon={deviceIcon}
                />
              }
              key="clients"
            ></TabPane>
            <TabPane
              tab={
                <NaviationTabPane name={_("Settings")} icon={settingsIcon} />
              }
              key="settings"
              style={{ display: "flex", justifyContent: "center" }}
            ></TabPane>
          </Tabs>
        )}
        {view === "routers" ? (
          <List
            itemLayout="horizontal"
            dataSource={data?.devices ?? []}
            loading={{ size: "large", spinning: isLoading }}
            renderItem={(device) => (
              <ListItemCard
                key={device.id}
                statistics={[
                  <Statistic
                    title={_("MAC Address")}
                    style={{ textAlign: isMobile ? "center" : "left" }}
                    key="macAddress"
                    value={device.mac_address}
                    valueStyle={{ fontSize: "1.2em" }}
                  />,
                  <HealthStatusStatistic
                    status={device.monitoring_status}
                    key="healthStatus"
                  />,
                ]}
                name={device.name}
                spaceSize={SPACE_SIZE}
                onClick={() => {
                  setDevice(device);
                }}
              />
            )}
          />
        ) : view === "clients" ? (
          <List
            itemLayout="horizontal"
            dataSource={data?.wifi_clients ?? []}
            loading={{ size: "large", spinning: isLoading }}
            renderItem={(client) => (
              <ListItemCard
                hoverable={false}
                key={client.mac_address}
                statistics={[
                  <Statistic
                    title={_("Mesh Point")}
                    style={{ textAlign: "center" }}
                    key="meshpoint"
                    value={client.access_point}
                    valueStyle={{ fontSize: "1.2em" }}
                  />,
                  <Statistic
                    title={_("Interface")}
                    style={{ textAlign: "center" }}
                    key="interface"
                    value={client.interface}
                    valueStyle={{ fontSize: "1.2em" }}
                  />,
                ]}
                name={client.vendor}
                description={client.mac_address}
                spaceSize={SPACE_SIZE}
                onClick={() => {
                  setDevice(device);
                }}
              />
            )}
          />
        ) : (
          <div
            style={{
              paddingTop: isMobile ? "12px" : "50px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {data?.template && <WifiSettingsForm template={data?.template} />}
          </div>
        )}
        <DeviceNameModal
          isVisible={device !== null}
          device={device}
          setIsVisible={setDevice}
        />
      </Container>
    </>
  );
};

export default HomeMeshSinglePage;
