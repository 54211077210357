import React from "react";
import "./Container.css";

interface ContainerProps {
  centerElements?: boolean;
  isMobile?: boolean;
}
export const Container: React.FC<ContainerProps> = ({
  children,
  centerElements,
  isMobile = false,
}) => {
  const padding = isMobile ? "5%" : "10%";
  return (
    <div
      className="container"
      style={{
        alignItems: centerElements ? "center" : "",
        paddingRight: padding,
        paddingLeft: padding,
      }}
    >
      {children}
    </div>
  );
};
