import React from "react";
import { Form, Input, message, Card, Button } from "antd";
import Cookies from "js-cookie";
import { gettext as _ } from "ttag";

interface WifiSettingsModalProps {
  template: {
    id: string;
    ssid: string;
    password: string;
  };
}

export const WifiSettingsForm: React.FC<WifiSettingsModalProps> = ({
  template,
}) => {
  const [form] = Form.useForm();
  form.setFieldsValue(template);
  const handleSubmit = async ({
    password,
    ssid,
  }: {
    password: string;
    ssid: string;
  }) => {
    if (password.length < 8) {
      form.setFields([
        {
          name: "password",
          errors: [_("Password should be longer than 8 characters.")],
        },
      ]);
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/home-mesh/template/${template.id}/`,
        {
          credentials: "include",
          method: "PUT",
          mode: "cors",
          body: JSON.stringify({
            ssid,
            password,
          }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") || "",
          },
        }
      );
      const data = await response.json();
      if (data.password && data.ssid) {
        message.success(_(
          "The new settings have been saved! Within the next 5 \
minutes all the mesh points will update their \
configuration."
        ));
      } else {
        message.error(_("Something went wrong. Please contact support."));
      }
    }
  };
  return (
    <Card style={{ maxWidth: "600px" }}>
      <Form
        form={form}
        layout="vertical"
        name="wifi-settings-form"
        onFinish={(values) => handleSubmit(values)}
      >
        <Form.Item
          name="ssid"
          label={_("SSID")}
          rules={[{ required: true, message: _("This field is required.") }]}
          initialValue={template.ssid}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label={_("Password")}
          rules={[{ required: true, message: _("This field is required.") }]}
          initialValue={template.password}
        >
          <Input />
        </Form.Item>
      </Form>
      <Button
        type="primary"
        style={{ float: "right" }}
        onClick={() => {
          form.submit();
        }}
      >
        {_("Save")}
      </Button>
    </Card>
  );
};
