import React from "react";

export type Device = {
  id: string;
  name: string;
  mac_address: string;
  monitoring_status: "ok" | "problem" | "critical" | "unknown";
};

export type WiFiClient = {
  mac_address: string;
  vendor: string;
  interface: string;
  access_point: string;
};

export type HomeMeshType = {
  id: string;
  name: string;
  user: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  template: {
    id: string;
    ssid: string;
    password: string;
  };
  devices: Array<Device>;
  wifi_clients: Array<WiFiClient>;
};

export const getTranslations = (language: string, locales: string[]) => {
  return locales.filter(
    (lang: string) =>
      lang.toLowerCase() === language ||
      // incase of "de" instead of "de_DE", return the next best
      lang.toLowerCase().substr(0, 2) === language
  )[0];
};

export const startPeriodicRefetch = (refetchFunction: () => void) => {
  const timeout = window.setInterval(() => {
    if (!document.getElementById('wifi-settings-form')) {
      refetchFunction();
    }
  }, ((process.env.REACT_APP_REFETCH_DELAY as unknown) as number) ?? 15000);
  return () => window.clearInterval(timeout);
};

export const IsMobileContext = React.createContext(false);
