import React from "react";
import { addLocale, useLocale } from "ttag";
import { createBrowserHistory } from "history";
import { Route, Router, Switch } from "react-router-dom";
import useAntdMediaQuery from "use-media-antd-query";

import { getTranslations, IsMobileContext } from "./utils";
import LoginPage from "./pages/LoginPage";
import HomeMeshListPage from "./pages/HomeMeshListPage";
import HomeMeshSinglePage from "./pages/HomeMeshSinglePage";
import NotFoundPage from "./pages/NotFoundPage";

import "antd/dist/antd.css";
const locales = require("./locales.json").locales;

function App() {
  const locale = getTranslations(navigator.language.toLowerCase(), locales);
  if (locale) {
    const translations = require(`./locales/${locale}.po.json`);
    addLocale(locale, translations);
  }
  useLocale(locale);
  const history = createBrowserHistory();
  const colSize = useAntdMediaQuery();
  return (
    <IsMobileContext.Provider value={["xs", "sm", "md"].includes(colSize)}>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/home-mesh" component={HomeMeshListPage} />
          <Route exact path="/home-mesh/:id/" component={HomeMeshSinglePage} />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </IsMobileContext.Provider>
  );
}

export default App;
