import React, { useContext, useEffect } from "react";
import { Form, Card, Input, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { gettext as _ } from "ttag";

import { Container, Logo } from "../components";
import { IsMobileContext } from "../utils";

const LoginPage: React.FC = () => {
  const isMobile = useContext(IsMobileContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const login = async (values: { username: string; password: string }) => {
    // strip accidental spaces
    values.username = values.username.trim();
    values.password = values.password.trim();
    return await fetch(`${process.env.REACT_APP_API_URL}/home-mesh/login/`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
  useEffect(() => {
    if (Cookies.get("csrftoken")) {
      history.push("/home-mesh");
    }
  }, [history]);
  const handleSubmit = async (values: {
    username: string;
    password: string;
  }) => {
    const response = await login(values);
    const json = await response.json();
    if (json.user) {
      history.push("/home-mesh");
      message.success(`${_("Welcome")} ${json.user}.`);
    } else {
      message.error(json.detail);
    }
  };

  return (
    <Container centerElements>
      <Card
        style={{
          width: isMobile ? "auto" : "45%",
          maxWidth: "480px",
          marginTop: "12.5%",
        }}
        bodyStyle={{ display: "flex", flexDirection: "column" }}
        className="login"
      >
        <Logo
          style={{
            width: "80%",
            maxWidth: "400px",
            margin: "15px auto 40px",
            alignSelf: "center",
          }}
        />
        <Form
          form={form}
          layout="vertical"
          name="login"
          onFinish={(values) => handleSubmit(values)}
        >
          <Form.Item
            label={_("Email Address")}
            name="username"
            rules={[{ required: true, message: _("This field is required.") }]}
          >
            <Input autoComplete="email" />
          </Form.Item>
          <Form.Item
            name="password"
            label={_("Password")}
            rules={[{ required: true, message: _("This field is required.") }]}
          >
            <Input.Password autoComplete="current-password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ float: "right" }}>
              {_("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Container>
  );
};

export default LoginPage;
