import React, { useContext } from "react";
import { Space, Card, List } from "antd";
import { IsMobileContext } from "../utils";

interface ListItemCardProps {
  statistics: JSX.Element[];
  name: string;
  spaceSize?: "small" | "middle" | "large" | number;
  onClick?: () => void;
  description?: string;
  hoverable?: boolean;
}

export const ListItemCard: React.FC<ListItemCardProps> = ({
  statistics,
  name,
  spaceSize = "small",
  onClick,
  description,
  hoverable = true,
}) => {
  const isMobile = useContext(IsMobileContext);
  return (
    <List.Item>
      <Card
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        hoverable={hoverable}
        bodyStyle={
          !isMobile
            ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }
            : {}
        }
      >
      <div style={isMobile ? { textAlign: "center" } : {}}>
        <h2>{name}</h2>
        <h4 className="ant-list-item-meta-description">{description ?? ""}</h4>
      </div>
        <Space
          size={spaceSize}
          style={{
            justifyContent: "center",
            textAlign: "center",
            width: isMobile ? "100%" : "auto",
          }}
        >
          {statistics.map((item) => item)}
        </Space>
      </Card>
    </List.Item>
  );
};
