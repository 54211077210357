import React, { useState, useEffect } from "react";

interface LogoProps {
  style?: React.CSSProperties;
}
export const Logo: React.FC<LogoProps> = ({ style = {} }) => {
  const [logo, setLogo] = useState("");
  const importLogo = async () => {
    const importedLogo =
      // @ts-ignore
      (await import(`../${process.env.REACT_APP_LOGO}`)) ?? "";
    setLogo(importedLogo.default);
  };
  useEffect(() => {
    importLogo();
  }, []);
  return <img src={logo} style={style} alt="Logo"/>;
};
