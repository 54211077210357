import React from "react";
import {
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Statistic, Tooltip } from "antd";
import { gettext as _ } from "ttag";

interface HealthStatusStatisticProps {
  status: "ok" | "problem" | "critical" | "unknown";
}

export const HealthStatusStatistic: React.FC<HealthStatusStatisticProps> = ({
  status,
}) => {
  let statusLabel = String(status);
  if (status === "ok") { statusLabel = _("online"); }
  if (status === "critical") { statusLabel = _("offline"); }
  if (status === "unknown") { statusLabel = _("unknown"); }
  return (
    <Statistic
      title={_("Status")}
      style={{ minWidth: "6rem" }}
      prefix={
        <Tooltip
          placement="top"
          title={`${statusLabel.charAt(0).toUpperCase()}${statusLabel.slice(1)}`}
        >
          {status === "unknown" ? (
            <QuestionCircleOutlined style={{ color: "gray" }} />
          ) : status === "critical" ? (
            <ExclamationCircleOutlined style={{ color: "red" }} />
          ) : (
            <CheckCircleOutlined style={{ color: "limegreen" }} />
          )}
        </Tooltip>
      }
      valueStyle={{ fontSize: "1.3rem" }}
      key="healthStatus"
      value=" "
    />
  );
};
