import React, { useEffect } from "react";
import { Form, Input, Modal, message } from "antd";
import Cookies from "js-cookie";
import { Device } from "../utils";
import { gettext as _ } from "ttag";
import slugify from "slugify";

interface DeviceNameModalProps {
  device: Device | null;
  isVisible: boolean;
  setIsVisible: (device: Device | null) => void;
}

export const DeviceNameModal: React.FC<DeviceNameModalProps> = ({
  isVisible,
  setIsVisible,
  device,
}) => {
  const [form] = Form.useForm();
  const setErrors = (errors: Array<string>) => {
    form.setFields([
      {
        name: "name",
        errors,
      },
    ]);
  };
  const handleSubmit = async (values: { name: string }) => {
    const name = slugify(values.name.replaceAll(/'/g, ""));
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/home-mesh/device/${device?.id}/`,
      {
        method: "PATCH",
        mode: "cors",
        body: JSON.stringify({
          name,
        }),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }
    );
    const json = await response.json();
    if (json.detail === "success") {
      setIsVisible(null);
      message.success(`${_("Successfully updated")} "${name}".`);
    } else {
      setErrors(json.name);
    }
  };
  // Ant design's initialValue is set only on the first render
  // thus needing manual updating of value on each modal open.
  useEffect(() => {
    form.setFieldsValue({
      name: device?.name,
    });
  }, [device, form]);
  return (
    <Modal
      title={`${_("Edit")} ${device?.name}`}
      visible={isVisible}
      okText={_("Save")}
      cancelText={_("Cancel")}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        setIsVisible(null);
      }}
      forceRender
    >
      <Form
        form={form}
        layout="vertical"
        name="changeDeviceName"
        onFinish={(values) => handleSubmit(values)}
      >
        <Form.Item
          name="name"
          label={_("Name")}
          rules={[{ required: true, message: _("This field is required.") }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
