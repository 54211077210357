import React, { useState } from "react";
import { Button, message } from "antd";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { Logo } from "./Logo";
import "./Navbar.css";
import { gettext as _ } from "ttag";

export const Navbar: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  return (
    <div className="navbar">
      <>
        <Logo style={{ height: "75px" }} />
      </>
      <Button
        loading={loading}
        style={{ cursor: "pointer" }}
        onClick={async () => {
          setLoading(true);
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/home-mesh/logout/`,
            {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({}),
              // @ts-ignore
              headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": Cookies.get("csrftoken") ?? "",
              },
            }
          );
          const data = await response.json();
          if (data.detail) {
            Cookies.remove("csrftoken");
            history.push("/");
            message.success(_("Successfully logged out"));
          }
        }}
      >
        {_("Logout")}
      </Button>
    </div>
  );
};
