import { QuestionOutlined } from "@ant-design/icons";
import { Result, Button, Card } from "antd";
import React from "react";
import { gettext as _ } from "ttag";
import { useHistory } from "react-router";

const NotFoundPage: React.FC = () => {
  const history = useHistory();
  return (
    <Card
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Result
        title={`404: ${_("Not Found")}`}
        icon={<QuestionOutlined />}
        subTitle={_("Sorry, the page you visited does not exist.")}
        extra={
          <Button
            type="primary"
            onClick={() => {
              history.push("/home-mesh");
            }}
          >
            {_("Back to Home")}
          </Button>
        }
      />
    </Card>
  );
};

export default NotFoundPage;
